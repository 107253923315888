<template>
  <div class="bt-transaksi pb-5 mb-5">
    <Pembiayaan />
    <Tabungan />
    <Others />
  </div>
</template>

<script>
import Pembiayaan from "@/views/Pembiayaan/Dashboard";
import Tabungan from "@/views/Tabungan/Dashboard";
import Others from "@/views/Others/Dashboard";
export default {
  name: "dtl",
  components: { Pembiayaan, Tabungan, Others },
};
</script>
